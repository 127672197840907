<template>
  <div class="home">
    <b-container fluid id="header">
      <b-container>
        <b-row>
          <b-col class="text-center">
            <hr style="width:30%;" />
            <h1>Bamboo Thaimassage</h1>
            <p class="pb-3">
              Die traditionelle, 2500 Jahre alte Anwendungsmethode für Geist und
              Körper (auch "Nuad" genannt), wird auf einen Zeitgenossen Buddhas
              zurückgeführt und kam von Nordindien über Umwege nach Thailand.
              Vielleicht erklärt das den ungewöhnlichen Mix aus Yoga-Übungen und
              Reflexzonenmassage.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center text-md-right mb-3">
            <b-button pill variant="primary" @click="$bvModal.show('mail')"
              >Gutschein bestellen</b-button
            >
          </b-col>
          <b-col class="text-center text-md-left mb-3">
            <b-button to="/#preise" pill variant="outline-primary"
              >Preise ansehen</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container id="about" class="p-5">
      <b-row>
        <b-col class="text-center"
          ><b-img
            :src="require(`@/assets/svg/bamboo.svg`)"
            fluid
            center
            alt="Logo"
            class="mt-3 mb-5"
          ></b-img>
          <h2 class="mb-5">Über uns</h2>
          <p class="mb-5">
            Der Gast soll sich in unseren Räumen wohlfühlen, entspannen und<br />
            Wohlergehen tanken.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col md="4">
          <b-img
            :src="require(`@/assets/img/about1.png`)"
            fluid
            center
            alt="Responsive image"
          ></b-img>
        </b-col>
        <b-col md="4" class="d-none d-md-block">
          <b-img
            :src="require(`@/assets/img/about2.png`)"
            fluid
            center
            alt="Responsive image"
          ></b-img>
        </b-col>
        <b-col md="4" class="d-none d-md-block">
          <b-img
            :src="require(`@/assets/img/about3.png`)"
            fluid
            center
            alt="Responsive image"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid id="massagen" class="p-5">
      <div class="swiper-button-prev-unique" slot="button-prev">
        <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
      </div>
      <div class="swiper-button-next-unique" slot="button-next">
        <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
      </div>
      <b-container>
        <swiper :options="swiperOption">
          <swiper-slide
            ><b-card
              title="Herbal Kräuter Massage"
              :img-src="require(`@/assets/img/herbal.jpg`)"
              img-alt="Herbal Kräuter Massage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Die traditionelle Thai Herbal Massage ist eine spezielle
                Massagetechnik mit Kräuterstempeln aus thailändischen Kräutern
                und Gewürzen. Mit den von Wasserdampf erhitzten Stempeln werden
                die Energiebahnen des Körpers massiert. Dies regt die
                Durchblutung an und wirkt entschlackend
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Fußreflexzonen Massage"
              :img-src="require(`@/assets/img/fussmassage.jpg`)"
              img-alt="Fußreflexzonen Massage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Bei der Fußreflexzonenmassage wird der komplette Fuß und die
                Unterschenkel mit einbezogen. Fußsohlen, Fußrücken sowie die
                Innenseite und Außenseite der Füße werden massiert. Die
                Überfunktion oder Unterfunktion der Organe wird positiv
                beeinflusst und ausgeglichen.
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Gesichtsmassage"
              :img-src="require(`@/assets/img/kopf_gesicht.jpg`)"
              img-alt="Gesichtsmassage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Bei der Gesichts-/Kopfmassage werden Verspannungen im Nacken und
                Kopfbereich gelöst und die Durchblutung kann angeregt werden.
                Sie wirkt tief entspannend und sorgt für ein allgemeines
                Wohlbefinden.
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Rückenmassage"
              :img-src="require(`@/assets/img/ruecken.jpg`)"
              img-alt="Rückenmassage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Bei unserer Rückenmassage werden intensive Verspannungen im
                Kopf-, Nacken- und Rückenbereich gezielt und nachhaltig gelöst.
                Wir verwenden hierbei ein Duft- oder Kräuteröl.
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Hot Stone Massage"
              :img-src="require(`@/assets/img/hotstone.jpg`)"
              img-alt="Hot Stone Massage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Bei einer Hot-Stone-Massage werden erwärmte glatte Lavasteine
                verwendet. Diese Lavasteine werden auf den Körper gelegt und
                erzielen durch ihre ausgehende Wärme eine Entspannung bis in die
                tiefen Muskelschichten. Dies steigert außerdem den
                Stoffwechselprozess im Gewebe.
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Akupressur- Ganzkörpermassage"
              :img-src="require(`@/assets/img/akupr.jpg`)"
              img-alt="Akupressur- Ganzkörpermassage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Die traditionelle Thailändische Massage unter der thailändischen
                Bezeichnung „Nuad Phaen Boran"- was soviel bedeutet wie „uralte
                heilsame Berührung" - ist eine Massageart die seit Jahrhunderten
                erfolgreich angewandt wird. Durch entsprechende Dehnungen und
                Streckungen sowie Bearbeitung der Akupressurpunkte und
                Energielinien wird ein anhaltendes körperliches Wohlbefinden
                erzielt.
              </b-card-text>
            </b-card></swiper-slide
          >
          <swiper-slide
            ><b-card
              title="Aromatherapie Massage"
              :img-src="require(`@/assets/img/aroma.jpg`)"
              img-alt="Aromatherapie Massage"
              img-top
              class="mb-2 text-center"
            >
              <b-card-text>
                Bei dieser Massageform können Sie zwischen Bio- und Aromaöl
                wählen. Die Aromamassage fördert den Abbau von Stress, Giften,
                Schlacken und wirkt beruhigend, krampflösend und
                schmerzlindernd. Das allgemeine Wohlbefinden wird dadurch
                gesteigert.
              </b-card-text>
            </b-card></swiper-slide
          >
        </swiper>
      </b-container>
    </b-container>
    <b-container id="preise" class="p-5">
      <b-row>
        <b-col class="text-center"
          ><b-img
            :src="require(`@/assets/svg/bamboo.svg`)"
            fluid
            center
            alt="Logo"
            class="mt-3 mb-5"
          ></b-img>
          <h2 class="mb-5">Preisliste</h2>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/feet.svg`)"
            fluid
            center
            alt="Fußreflexzonenmassage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="7" class="text-center text-lg-left"
          ><h5 class="my-2">Fußreflexzonenmassage</h5></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>45 Minuten</b><br /><span class="price-highlight">37€</span> <span class="price-new">33€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>60 Minuten</b><br /><span class="price-highlight">45€</span> <span class="price-new">40€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/face.svg`)"
            fluid
            center
            alt="Gesichtsmassage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Gesichtsmassage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">inkl. Kopf und Schultern</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="12" class="text-center"
              ><p class="my-2">
                <b>30 Minuten</b><br /><span class="price-highlight">27€</span> <span class="price-new">24€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/009-massage.svg`)"
            fluid
            center
            alt="Rückenmassage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Rückenmassage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">inkl. Kopf und Schultern</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>30 Minuten</b><br /><span class="price-highlight">27€</span> <span class="price-new">24€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>45 Minuten</b><br /><span class="price-highlight">38€</span> <span class="price-new">34€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/009-massage.svg`)"
            fluid
            center
            alt="Akupressur- Ganzkörpermassage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Akupressur- Ganzkörpermassage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">Ganzkörpermassage ohne Öl</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>60 Minuten</b><br /><span class="price-highlight">45€</span> <span class="price-new">40€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">65€</span> <span class="price-new">59€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/massage.svg`)"
            fluid
            center
            alt="Aroma- Ganzkörpermassage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Aroma- Ganzkörpermassage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">Ganzkörpermassage mit Duftöl</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>60 Minuten</b><br /><span class="price-highlight">48€</span> <span class="price-new">43€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">68€</span> <span class="price-new">61€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/009-massage.svg`)"
            fluid
            center
            alt="Öl Massage klassisch"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="7" class="text-center text-lg-left"
          ><h5 class="my-2">Öl Massage klassisch</h5></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>60 Minuten</b><br /><span class="price-highlight">46€</span> <span class="price-new">41€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">63€</span> <span class="price-new">57€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/massage.svg`)"
            fluid
            center
            alt="Thai Balance DELUXE Bioöl"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Thai Balance DELUXE Bioöl</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">Olive / Argan / Jojoba / Almond</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>60 Minuten</b><br /><span class="price-highlight">55€</span> <span class="price-new">50€</span>
              </p></b-col
            >
            <b-col lg="6" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">65€</span> <span class="price-new">59€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/wellness.svg`)"
            fluid
            center
            alt="Hot Stone Massage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Hot Stone Massage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">Mit warmen Lavasteinen</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="12" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">69€</span> <span class="price-new">62€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <b-row class="price mb-3 align-items-center">
        <b-col lg="1"
          ><b-img
            :src="require(`@/assets/svg/massage.svg`)"
            fluid
            center
            alt="Thai Herbal Massage"
            class="my-2"
          ></b-img
        ></b-col>
        <b-col lg="3" class="text-center text-lg-left"
          ><h5 class="my-2">Thai Herbal Massage</h5></b-col
        >
        <b-col lg="4" class="text-center text-lg-left"
          ><p class="my-2">Mit Kräuterkompressen</p></b-col
        >
        <b-col lg="4">
          <b-row>
            <b-col lg="12" class="text-center"
              ><p class="my-2">
                <b>90 Minuten</b><br /><span class="price-highlight">69€</span> <span class="price-new">62€</span>
              </p></b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid id="buchen" class="p-5">
      <b-container>
        <b-row>
          <b-col class="text-center"
            ><b-img
              :src="require(`@/assets/svg/bamboo.svg`)"
              fluid
              center
              alt="Responsive image"
            ></b-img>
            <h2>Jetzt Termin buchen!</h2>
            <p class="h3 my-5">
              <a href="tel:052518785050"
                ><b-icon
                  icon="telephone-fill"
                  class="mr-2 align-middle"
                  font-scale="1.5"
                ></b-icon>
                05251 8785050</a
              >
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <l-map
              id="karte"
              :zoom="zoom"
              :center="center"
              :options="{ zoomControl: false, scrollWheelZoom: false }"
            >
              <l-control-zoom position="bottomright"></l-control-zoom>
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker :lat-lng="markerLatLng"></l-marker>
            </l-map>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-modal
      id="mail"
      size="xl"
      centered
      title="Geschenkgutschein bestellen"
      hide-footer
      scrollable
    >
      <!--
      <div>
        <div>
          <transition name="fade" mode="out-in">
            <div v-if="sent">
              <p>Thanks</p>
            </div>
          </transition>
        </div>
        <div v-if="!sent" class="formGroup">
          <b-form @submit="onSubmit">
            <b-form-input
              id="input-name"
              v-model="form.name"
              type="text"
              required
              placeholder="Name"
            />
            <b-button type="submit">
              Contact
            </b-button>
          </b-form>
        </div>
      </div>
      -->
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <b-row v-if="sent" class="m-5">
            <b-col cols="12">
              <p class="text-center">
                <b-img
                  :src="require(`@/assets/img/check-circle.gif`)"
                  width="100"
                  height="100"
                  center
                ></b-img>
                <b>Vielen Dank für Ihre Bestellung!</b><br />
                Bitte überweisen Sie den erforderlichen Betrag auf folgendes
                Konto:<br /><br />
                Bankdaten:<br />
                Bamboo Thaimassage<br />
                Robin Töppe<br />
                IBAN : DE46 5205 0353 0011 8410 67<br />
                BIC: HELADEF1KAS<br /><br />
                Sobald das Geld eingegangen ist, schicken wir Ihren Gutschein
                per Post zu Ihnen.
              </p>
            </b-col>
          </b-row>

          <b-form v-else @submit="sendForm" class="p-5">
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="input-group-1"
                  label="Vorname*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="ContactForm.vorname"
                    required
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="input-group-2"
                  label="Nachname*"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="ContactForm.nachname"
                    required
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group
                  id="input-group-6"
                  label="Straße / Hausnummer*"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    v-model="ContactForm.street"
                    required
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-7"
                  label="PLZ / Ort*"
                  label-for="input-7"
                >
                  <b-form-input
                    id="input-7"
                    v-model="ContactForm.city"
                    required
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group
                  id="input-group-3"
                  label="E-Mail-Adresse*"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="ContactForm.email"
                    type="email"
                    required
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  id="input-group-8"
                  label="Telefonnummer"
                  label-for="input-8"
                >
                  <b-form-input
                    id="input-8"
                    v-model="ContactForm.phone"
                    placeholder="Bitte eingeben..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  id="input-group-9"
                  label="Anzahl*"
                  label-for="input-9"
                >
                  <b-form-select
                    id="input-9"
                    v-model="ContactForm.personen"
                    :options="personen"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="9">
                <b-form-group
                  id="input-group-4"
                  label="Massagen*"
                  label-for="input-4"
                >
                  <b-form-select
                    id="input-4"
                    v-model="ContactForm.massagen"
                    :options="massagen"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group id="input-group-5">
              <b-form-checkbox
                required
                name="datacheck"
                value="datacheck"
                v-model="ContactForm.datacheck"
                id="checkboxes-5"
                >Ich habe die
                <router-link
                  to="/datenschutz#data"
                  target="_blank"
                  class="legallink"
                  >Datenschutzerklärung</router-link
                >
                zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und
                Daten zur Beantwortung meiner Anfrage temporär elektronisch
                gespeichert werden.*</b-form-checkbox
              >
            </b-form-group>
            <b-row>
              <b-col>
                <p>
                  Die Einwilligung kann jederzeit für die Zukunft per
                  <a
                    href="mailto:thaimassage.paderborn@gmail.com"
                    target="_blank"
                    class="legallink"
                    >E-Mail</a
                  >
                  widerrufen werden.
                </p>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col><small>*Pflichtfelder</small></b-col>
            </b-row>
            <b-button type="submit" variant="primary">Absenden</b-button>
          </b-form>
        </transition>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
const querystring = require("querystring");
import { LMap, LTileLayer, LControlZoom, LMarker } from "vue2-leaflet";
export default {
  name: "Home",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
  },
  data() {
    return {
      sent: false,
      ContactForm: {
        vorname: "",
        nachname: "",
        street: "",
        city: "",
        email: "",
        phone: "",
        personen: "",
        massagen: "",
        datacheck: false,
      },
      selected: null,
      personen: [
        { text: "Bitte wählen die Anzahl der Personen aus...", value: null },
        "1 Person",
        "2 Personen",
        "3 Personen",
        "4 Personen",
      ],
      massagen: [
        { text: "Bitte wählen Sie ein Massage aus...", value: null },
        "Fußreflexzonenmassage 45 Min - 40 €",
        "Fußreflexzonenmassage 60 Min - 50 €",
        "Gesichtsmassage 30 Min - 30 €",
        "Rückenmassage 30 Min - 30 €",
        "Rückenmassage 45 Min - 40 €",
        "Akupressur-Ganzkörpermassage 60 Min - 50 €",
        "Akupressur-Ganzkörpermassage 90 Min - 72 €",
        "Aroma-Ganzkörpermassage 60 Min - 50 €",
        "Aroma-Ganzkörpermassage 90 Min - 72 €",
        "Öl Massage klassisch 60 Min - 41 €",
        "Öl Massage klassisch 90 Min - 57 €",
        "Thai Balance DELUXE Bioöl 60 Min - 50 €",
        "Thai Balance DELUXE Bioöl 90 Min - 59 €",
        "Hot Stone Massage 90 Min - 75 €",
        "Thai Herbal Massage 90 Min - 75 €",
      ],

      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 17,
      center: [51.71799, 8.7483],
      bounds: null,
      markerLatLng: [51.71799, 8.7483],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next-unique",
          prevEl: ".swiper-button-prev-unique",
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
      },
    };
  },
  methods: {
    sendForm(e) {
      e.preventDefault();
      //console.log(this.ContactForm);
      axios
        .post(
          "https://thaimassage-bamboo.de/mail.php",
          querystring.stringify(this.ContactForm)
        )
        // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.sent = true;
        });
    },
  },
};
</script>
